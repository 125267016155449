/* styles.css */
strong {
  font-weight: bold;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}

em {
  font-style: italic;
}

a {
  color: blue;
  text-decoration: underline;
}

blockquote {
  margin: 1em 0;
  padding: 0.5em 10px;
  border-left: 5px solid #ccc;
  color: #666;
  background-color: #f9f9f9;
}

pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
}

code {
  font-family: monospace;
}

.streamingTestLoader {
  background: linear-gradient(
    to right,
    #000000,
    #2e2e2e,
    #585858,
    #878787,
    #b9b9b9,
    #b9b9b9,
    #000000,
    #b9b9b9,
    #878787,
    #585858,
    #2e2e2e,
    #b9b9b9
  );
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate-gradient 2s linear infinite;
}

@keyframes animate-gradient {
  0% {
    background-position: right;
  }
  /*    50% {
    background-position: 100% 50%;
  } */
  100% {
    background-position: left;
  }
}
