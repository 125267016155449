.youtube {
  width: 350px;
  height: 710px;
  border: solid;
  border-radius: 25px;
}

.faqsDiv::-webkit-scrollbar {
  width: 0;
}

@media screen and (min-width: 600px) {
  .youtube {
    width: 58.5%;
    height: 100%;
  }
}
